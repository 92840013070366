declare let jic: any
//import { CartProvider } from '@/contexts/cart'
import GlobalStyle from '../styles/GlobalStyle'
import { ConfigProvider } from 'antd'
import ptPT from 'antd/lib/locale/pt_PT'
import { Provider } from 'next-auth/client'
import { ClientProvider } from '@/contexts/ClientContext'
import { CategoryProvider } from '@/contexts/CategoryContext'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MultiVendorCartProvider } from '@/contexts/MultiVendorCartContext'
import TagManager from 'react-gtm-module'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const products = []
  const attributeOpt = []
  const attributes = []

  let gtmParams
  if (process.env.NEXT_PUBLIC_NODE_ENV == 'production') {
    gtmParams = {
      id: 'GTM-52WSK4L',
      environment: {
        gtm_auth: 'Zp8EnKn19HL53WEpdDYcrA',
        gtm_preview: '4',
      },
    }
  } else {
    gtmParams = {
      id: 'GTM-PKZGZDB',
      environment: {
        gtm_auth: '_3hnELTocT3y1NXW2TPJnA',
        gtm_preview: 'env-1',
      },
    }
  }

  useEffect(() => {
    window.fbq('track', 'PageView')
    const handleRouteChange = (url) => {
      if (typeof jic !== 'undefined') {
        jic.triggerNewPageView()
      }
      window.fbq('track', 'PageView')
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <GTMProvider state={gtmParams}>
      <Provider session={pageProps.session}>
        <ConfigProvider locale={ptPT}>
          <ClientProvider>
            {/* <CartProvider> */}
            <MultiVendorCartProvider>
              <CategoryProvider
                products={products}
                attributeOptions={attributeOpt}
                attributes={attributes}
              >
                <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                />
                <GlobalStyle />
                <Component {...pageProps} />
              </CategoryProvider>
            </MultiVendorCartProvider>
            {/* </CartProvider> */}
          </ClientProvider>
        </ConfigProvider>
      </Provider>
    </GTMProvider>
  )
}

export default MyApp
