import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
  }

  html, body, #__next{
    min-height: 100%;
  }

  #__next{
    display: flex
  }

  a{
    /* text-decoration: none;
    color: #43043B;
    transition: all 0.3s;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    :hover{
      color: #67085b;
    } */
  }

  /* label{
    margin: 10px 0 5px;
    display: block;
  }

  input:not([type='checkbox']) {
    height: 36px;
    min-width: 300px;
    padding: 0 10px;
  }

  input[type="checkbox"] + label{
    display: inline-block;
    margin: 10px 10px 5px;
  }

  button{
    display: block;
    margin: 10px 0;
    background: #F16749;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 20px;
    cursor: pointer;
  } */

  img{
    max-width: 100%;
    height: auto;
  }

  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 40px;
    margin: 20px 0 0;
  }
  @media (max-width: 600px){
    .content-container.erro-404-container h1{
      font-size: 20px !important;
      line-height: 26px !important;
    }

  }


  .ant-table-title .ant-typography {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #393939;
  }

  .ant-carousel .slick-slider{
    max-width: 99.1vw;
    /* overflow: hidden */
  }



  .ant-breadcrumb {
    font-family: 'Lato';
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]{
    appearance: none!important;
  }

  .ant-menu-horizontal > .ant-menu-item.has-submenu {
    position: unset;
  }

  .ant-menu-horizontal > .ant-menu-item.has-submenu > a {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #43043b;
  }

  .ant-menu-horizontal .submenu-dropdown {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 80px;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    padding: 32px 0px 60px;
    line-height: unset !important;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
  }

  .ant-menu-horizontal > .has-submenu.ant-menu-item-active .submenu-dropdown {
    visibility: visible;
    opacity: 1;
  }

  .ant-menu-horizontal > .ant-menu-item.has-submenu a::before {
    display: none;
  }

  .ant-menu-horizontal .submenu-dropdown .container {
    max-width: 1400px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    line-height: unset !important;
  }

  .ant-menu-horizontal .submenu-dropdown .submenu-row {
    display: flex;
    flex-wrap: wrap;
    line-height: unset !important;
    align-items: flex-start;
  }

  .ant-menu-horizontal .submenu-dropdown .submenu-title {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #7B2E6F;
    margin: 0px 10px 8px;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-menu-horizontal .submenu-dropdown .submenu-list {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: unset !important;
  }

  .ant-menu-horizontal .submenu-dropdown .submenu-image {
    max-width: 447px;
    padding-right: 10px;
  }

  .ant-menu-horizontal .submenu-dropdown .submenu-image img {
    max-height: 223px;
    object-fit: cover;
  }

  .ant-menu-horizontal .submenu-dropdown ul {
    flex: 0 0 600px;
    max-width: 600px;
    line-height: unset !important;
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
  }

  .ant-menu-horizontal .submenu-dropdown ul li {
    line-height: 24px !important;
    height: auto !important;
    padding: 0 20px 0 0 !important;
    margin: 0 !important;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ant-menu-horizontal .submenu-dropdown ul li a {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px !important;
    color: #6B6B6B;
    padding: 8px 0;
    display: block;
  }

  .ant-menu-horizontal .submenu-dropdown ul li a.see-all {
    color: #f16749;
    transition: all 0.5s;
  }

  .ant-menu-horizontal .submenu-dropdown ul li a.see-all:hover {
    opacity: 0.5;
  }

  .ant-menu-horizontal .submenu-dropdown ul li a:before {
    display: none;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    color: #393939;
  }

  .ant-btn-text{
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #7B2E6F;

    span{

      text-decoration-line: underline;
    }
  }

  .ant-picker-header-view button{
    color: initial;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    .ant-menu-horizontal .submenu-dropdown .container {
        padding: 0 30px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .ant-menu-horizontal .submenu-dropdown .container {
        padding: 0 30px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .ant-menu-horizontal .submenu-dropdown {
      top: 80px;
    }
  }

  .content-container {
    max-width: 1400px;
    margin: 0 auto;
  }

  .content-row {
    display: flex;
    flex-wrap: wrap;
  }

  .top-filter {
    padding-top: 32px;
    padding-bottom: 40px;
  }

  .top-filter .category-name {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6B6B6B;
    display: flex;
    align-items: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .top-filter .category-name h1 {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #F16749;
    margin-bottom: -7px;
    margin-right: 12px;
  }

  .layered-navigation {
    flex: 0 0 208px;
    max-width: 208px;
  }

  .layered-navigation .layered-navigation-item {
    padding-bottom: 32px;
  }

  .layered-navigation .layered-navigation-item + .layered-navigation-item {
    padding-top: 32px;
    border-top: 1px solid #E2E2E2;
  }

  .layered-navigation .layered-navigation-item .layered-navigation-item-name {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #393939;
    margin-bottom: 12px;
  }

  .layered-navigation .layered-navigation-item ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .layered-navigation .layered-navigation-item ul li + li {
    margin-top: 8px;
  }

  .layered-navigation .layered-navigation-item ul li .ant-checkbox-wrapper {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
  }

  .product-grid {
    flex-basis: 0;
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 60px 30px;
  }

  .product-list-item {
    text-align: center;
    display: block;

  }

  .product-list-item-image{
    position: relative;
  }

  .product-list-item .ant-image {
    height: 278px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-list-item .ant-image img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
  }

  .product-list-item .product-list-item-name {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #43043B;
    margin-top: 8px;
    margin-bottom: 0;
  }

  .product-list-item .product-info{
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    text-align: center;

    color: #6B6B6B;
    margin-bottom: 8px;
    margin-top: 4px;
    display: block;
  }

  .product-list-item .product-list-item-price {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #43043B;
    margin-bottom: 0;
  }

  .ant-pagination {
    margin-top: 60px;
    text-align: center;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: 0;
    border-bottom: 2px solid transparent;
    color: #393939;
  }

  .ant-pagination-item a {
    color: #7B2E6F;
  }

  .ant-pagination-item {
    border: 0;
    border-bottom: 2px solid transparent;
  }

  .ant-pagination-item-active a {
    color: #7B2E6F;
  }

  .ant-pagination-item-active {
    border-color: #7B2E6F;
    pointer-events: none;
  }

  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: #7B2E6F;
  }

  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: #7B2E6F;
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #7B2E6F;
    border-color: #7B2E6F;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(57, 57, 57, 0.25);
  }

  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    border-color: transparent;
  }

  .category-page-container {
    padding-top: 32px;
    padding-bottom: 100px;
  }

  .account-page-container {
    padding-top: 32px;
    padding-bottom: 52px;
  }

  .account-title-container {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #F16749;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }

  .account-title-container.w-subtitle {
    margin-bottom: 4px;
  }

  .account-title-container .ant-tag {
    margin-left: 12px;
    vertical-align: middle;
  }

  .account-subtitle-container {
    margin-bottom: 20px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6B6B6B;
    text-transform: capitalize;
    text-align: center;
  }

  .account-nav-col {
    flex: 0 0 208px;
    max-width: 208px;
  }

  .account-nav {
    background: #FFFFFF;
    box-shadow: 1px 0px 0px #F0F0F0;
  }

  .account-nav a {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
    display: block;
    padding: 8px 16px;
  }

  .account-nav a.active {
    color: #F16749;
    background: #FEF3F1;
    box-shadow: inset -3px 0px 0px #F16749;
  }

  .account-content {
    flex-basis: 0;
    flex-grow: 1;
    padding-left: 30px;
  }

  .account-form-section .account-form-section-title {
    padding: 12px 16px;
    background: #F8F5F8;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #43043B;
  }

  .account-form-section + .account-form-section {
    margin-top: 56px;
  }

  .account-form-section .account-form-row {
    display: flex;
    flex-wrap: wrap;
  }

  .account-form-section .account-form-row .account-form-col {
    padding: 16px;
    border-bottom: 1px solid #E2E2E2;
  }

  .account-form-section .account-form-row .account-form-col.left {
    flex-basis: 0;
    flex-grow: 1;
  }

  .account-form-section .account-form-row .account-form-col.right {
    text-align: left;
    flex: 0 0 232px;
    max-width: 232px;
  }

  .account-form-section .account-form-row .account-form-col .account-form-label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #43043B;
    margin-bottom: 0;
  }

  .account-form-section .account-form-row .account-form-col .account-form-value {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6B6B6B;
  }

  @media (max-width: 700px){
    .account-form-section .account-form-row .account-form-col .account-form-value {
      display:flex;
      justify-content: center;
    }
  }

  .account-form-section .account-form-row .account-form-col .account-form-fields {
    max-width: 400px;
  }

  .account-form-section .account-form-row .account-form-col .account-form-fields-half {
    max-width: calc(50% - 15px);
  }

  .account-form-section .account-form-row .account-form-col .account-form-fields-half + .account-form-fields-half {
    margin-left: 30px;
  }

  .account-form-section .account-form-row .account-form-col .account-form-fields .ant-input + .ant-input {
    margin-top: 15px;
  }

  .account-form-section .account-form-row .account-form-col .account-form-fields .address-input {
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 10.4px 11px;
    color: #393939;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
  }

  .account-form-section .account-form-row .account-form-col .account-form-fields .address-input:focus {
    border-color: #ff9375;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(241 103 73 / 20%);
  }

  .account-form-section .account-form-row .account-form-col .account-form-fields .form-error {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: red;
  }

  .account-form-section .account-form-row .account-form-col.right a,
  .account-form-section .account-form-row .account-form-col.right button {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #F16749;
    padding: 0;
    border: 0;
    background: 0;
    cursor: pointer;
  }

  .account-form-section .account-form-row .account-form-col.right a:focus,
  .account-form-section .account-form-row .account-form-col.right button:focus {
    outline: 0
  }

  .account-form-section .account-form-row .account-form-col.right a + a,
  .account-form-section .account-form-row .account-form-col.right a + button,
  .account-form-section .account-form-row .account-form-col.right button + button,
  .account-form-section .account-form-row .account-form-col.right button + a {
    margin-left: 16px;
  }

  .account-table .ant-table-thead > tr > th {
    background: #F8F5F8;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #43043B;
    border-radius: 0 !important;
    padding: 12px 16px;
  }

  .account-table .ant-table-tbody > tr > td {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
    padding: 12px 16px;
    border-bottom: 1px solid #E2E2E2;
  }

  .account-table .ant-table-tbody > tr > td a.purple-link {
    color: #7B2E6F;
  }

  .styled-btn {
    align-items: center;
    padding: 10px 24px;
    background: #F16749;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    border-radius: 0;
    border: 0;
    text-shadow: unset;
    box-shadow: unset;
    cursor: pointer;
  }

  .styled-btn:focus {
    outline: 0;
  }

  .styled-btn:hover {
    color: #fff;
    background: #ff9375;
    border-color: #ff9375;
  }

  .cms-account-orders-view h2 {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #7B2E6F;
  }

  .cms-account-orders-view .order-total {
    text-align: right;
    font-size: 14px;
    line-height: 24px;
    color: rgb(107, 107, 107);
  }

  .cms-account-orders-view .order-total strong {
    font-size: 18px;
    line-height: 24px;
    color: rgb(123, 46, 111);
  }

  .content-container.erro-404-container {
    padding: 60px 15px 100px;
    text-align: center;
  }

  .content-container.erro-404-container h1 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #43043B;
  }

  .content-container.erro-404-container p {
    margin-bottom: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
  }

  .content-container.home-page-container {
    padding: 40px 0 100px;
  }

  //  ---- START formulário devolução ----

  .fd-top {
    padding-top: 50px;
  }

  .fd-top h1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #43043B;
    margin-bottom: 12px;
    text-align: center;
  }

  .titles-table .ant-form-item-label{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
  }

  .titles-table .ant-input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .titles-table .ant-input-textarea-show-count::after {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .text-under-button{
    margin-top:28px;
    text-align:center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
    margin-bottom: 2px;
  }

  .char-count{
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(57, 57, 57, 0.4);
    text-align:right;
    margin: 0;
  }

  .link-center{
    text-align:center;
  }

  .link{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #F16749;
    text-align:center;
    display:inline-block;
  }

  .link + .link {
    margin-left: 16px;
  }

  .link1{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #F16749;
    text-align:center;
    display:inline-block;
  }
  .link2{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #F16749;
    margin-left:16px;
  }

  .table-center {
    margin: auto;
    margin-top:28px;
    width: 565px;
  }

  .table-center button{
    width:100%;
    border:0;
    height:40px;
    background:#F16749;
    margin-top:38px;
    color:white;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border: none;
    outline: none;
  }


  .table-inputs{
    display: flex;

    flex-direction: column;
    outline: none;
    align-items: flex-start;
    width:100%;
    height:48px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
  }

  .input-textarea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:12px;
    outline: none;
    align-items: flex-start;
    width:100%;
    height:78px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
  }


  @media screen and (max-width:768px){

    .breadcrumb{
      display:none;
    }

    .fd-top{
      padding:0;
    }

    .fd-top h1{
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 38px;
      color: #43043B;
      margin-bottom: 12px;
      text-align: center;
    }

    .table-center {
      margin: auto;
      margin-top:28px;
      width: 100%;
    }

    .content-container.home-page-container.returns-form-page {
      padding: 40px 24px 100px;
    }

  }

  @media (max-width: 350px) {
    .returns-form-page .link-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .returns-form-page .link-center .link {
      margin: 0;
    }
  }

//  ---- END formulário devolução ----

// ----- START help buying -----

  .hb-top {
    padding-top: 50px;
  }

  .hb-top h1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #43043B;
    margin-bottom: 0px;
    text-align: left;
  }
  .hb-top p {
    font-family: Sora;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #6B6B6B;
    margin-bottom: 16px;
    text-align: left;
  }

  .leftt {
    margin: 0;
    margin-top:16px;
    width: 566px;
    float:left;
    margin-bottom: 100px;
  }

  .leftt button{
    width:100%;
    border:0;
    height:40px;
    background:#F16749;
    margin-top:38px;
    color:white;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border: none;
    outline: none;
  }

  .rightt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    margin-left:387px;
    margin-right:0;
    width:447px;
    height:374px;
    margin-top:0px;
    background: #FEF3F1;
    float:right;
  }

  .rightt h1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #393939;
  }

  .p-faqs1{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-left: 12px;
    line-height: 24px;
    margin-top:22px;
    margin-bottom:0;
    color: #6B6B6B;
  }
  .p-faqs{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-left: 12px;
    line-height: 24px;
    margin-top:16px;
    margin-bottom:0;
    color: #6B6B6B;
  }

  .button-faqs{
    margin-top:32px;
    width: 122px;

    padding: 10px 24px;
    background: #F16749;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border:none;
  }

  .upload-text{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #F16749;
    padding-bottom:12px;
  }

  @media screen and (max-width:1460px){
    .rightt {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      margin-left:150px;
      margin-right:0;
      width:447px;
      height:374px;
      margin-top:0px;
      background: #FEF3F1;
      float:right;
    }
    .hb-top h1{
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #43043B;
      margin-bottom: 16px;
      text-align: left;
    }

  }
  @media screen and (max-width:1223px){

    .hb-top {
      padding-top: 0px;
    }

    .hb-top h1{
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      margin-left:16px;
      font-size: 30px;
      line-height: 38px;
      color: #43043B;
      margin-bottom: 16px;
      text-align: center;
    }
    .hb-top p {
      text-align: center;
    }
    .leftt {
      margin-top:10px;
      padding-left:24px;
      padding-right:24px;
      width: 100%;
      margin-bottom: 68px;
    }
    .rightt {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      width: 100%;
      margin-bottom:0;
      background: #FEF3F1;
    }
  }
  @media (max-width: 500px) {
    .hb-top p {
      font-size: 14px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }


// ---- END help buying ----

// ------START FAQS -----

.faqs-top {
  padding-top: 44px;
  float:left;
  position: sticky;
  top:70px;
}

.faqs-top h1{

  font-family: Sora;
font-style: normal;
font-weight: 600;
font-size: 18px;
margin-bottom:11px;
line-height: 26px;
color: #393939;
}

.faqs-top p{

  font-family: Sora;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
color: #F16749;
}
.faqs-center {
  margin: auto;
  margin-top:48px;

  width: 685px;
}

.principal-title{
  font-family: Sora;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 38px;
color: #F16749;
}

.titles1{
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #43043B;
  margin-top:32px;
  margin-bottom:0;
}
.titles{
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #43043B;
  margin-top:40px;
  margin-bottom:0;
}
.sub-title{
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-top:12px;
  color: #43043B;
}
.body-text{
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top:12px;
  display: flex;
  margin-bottom:0px;
  align-items: center;
  color: #6B6B6B;
}
.timeline-mobile{
  display:none;
}
@media screen and (max-width:1199px){
  .breadcrumb{
    margin-left:8px;
  }
  .faqs-top {
    margin-left:12px;
  }
  .faqs-center {
    width: 100%;
    padding-left:220px;
    padding-right:50px;

  }
  .principal-title{
    font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */


  /* Orange / 40 - P */

  color: #F16749;
  }

  .titles1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #43043B;
  }
  .titles{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #43043B;
  }
  .sub-title{
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #43043B;
  }
  .body-text{
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #6B6B6B;
  }

}

@media screen and (max-width:769px){

  .breadcrumb{
    display:none
  }

  .faqs-top {
    background: #fff;
      width:100%;
      padding-top:0;
      padding-left:24px;
      padding-right:24px;
      padding-bottom:0;
      margin-left:0;
      text-align: center;
      margin-bottom:40px;
      z-index:9;

  }
  .faqs-center {
    width: 100%;
    padding-left:24px;
    padding-right:24px;

  }
    .timeline-desktop{
    display:none;
  }
  .timeline-mobile{
    padding:0;
    margin:0;
    display:block;
    width:100%;
    text-align: left;
  }


}


// ----- END FAQS
  .hp-top {
    padding-bottom: 53px;
  }

  .hp-top h1 {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #F16749;
    margin-bottom: 0;
    text-align: center;
  }

  .hp-top .hp-cat-badges {
    justify-content: center;
  }

  .hp-top .hp-cat-badge a {
    position: relative;
    margin-top: 30px;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #FFFFFF;
    padding: 32px 35px;
    background-size: cover;
    background-position: center;
    display: block;
    text-align: center;
  }

  .hp-top .hp-cat-badge a:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hp-top .hp-cat-badge a span {
    position: relative;
    z-index: 1;
  }

  .hp-section {
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;
  }

  .hp-section + .hp-section {
    border-top: 1px solid #C4C4C4;
  }

  .hp-section .hp-section-title {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #7B2E6F;
    margin-bottom: 16px;
  }

  .hp-section .hp-section-link {
    display: inline-block;
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #7B2E6F;
    margin-bottom: 32px;
  }

  .hp-section .ant-carousel {
    max-width: calc(100% - 50px);
    margin: 0 auto;
  }

  .hp-section .ant-carousel .slick-slider {
    width: 100%;
    max-width: calc(100vw - 60px);
    overflow: unset;
  }


  .slick-prev::before,.ant-carousel .slick-next::before {
    width: 16px;
    height: 16px;
    display: block;
  }

  .ant-carousel .slick-prev::before {
    background-image: url('/assets/images/arrow-left.svg');
  }

  .ant-carousel .slick-next::before {
    background-image: url('/assets/images/arrow-right.svg');
  }

  .account-page-container.cms-account-orders-view .account-title-container,
  .account-page-container.cms-account-orders-view .account-subtitle-container {
    max-width: 923px;
    text-align: left;
    margin-left: calc(100% - 923px);
  }

  .account-page-container.cms-account-orders-view .account-content {
    padding-left: calc(100% - 923px - 208px);
    padding-top: 20px;

  }



  .orderTotalInfoWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: -18px;
    margin-bottom: 40px;
    margin-right: 25px;
  }
  .orderTotalInfoWrapperOldOrders {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  .orderTotalInfoWrapper .orderTotalInfo {
    flex: 0 0 328px;
    max-width: 328px;
    background-color: #F8F5F8;
    padding: 16px;
  }

  .orderTotalInfoWrapper .orderTotalInfo .orderTotalRow {
    display: flex;
    flex-wrap: wrap;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
  }

  .orderTotalInfoWrapper .orderTotalInfo .orderTotalRow + .orderTotalRow {
    margin-top: 12px;
  }

  .orderTotalInfoWrapper .orderTotalInfo .orderTotalRow:last-child {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #E2E2E2;
    font-weight: bold;
    font-size: 16px;
    color: #393939;
  }

  .orderTotalInfoWrapper .orderTotalInfo .orderTotalRow .orderTotalLabel {
    flex: 1;
  }

  .orderTotalInfoWrapperOldOrders .orderTotalInfo {
    flex: 0 0 328px;
    max-width: 328px;
    background-color: #F8F5F8;
    padding: 16px;
  }

  .orderTotalInfoWrapperOldOrders .orderTotalInfo .orderTotalRow {
    display: flex;
    flex-wrap: wrap;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
  }

  .orderTotalInfoWrapperOldOrders .orderTotalInfo .orderTotalRow + .orderTotalRow {
    margin-top: 12px;
  }

  .orderTotalInfoWrapperOldOrders .orderTotalInfo .orderTotalRow:last-child {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #E2E2E2;
    font-weight: bold;
    font-size: 16px;
    color: #393939;
  }

  .orderTotalInfoWrapperOldOrders .orderTotalInfo .orderTotalRow .orderTotalLabel {
    flex: 1;
  }

  .order-aditional-info-wrapper {
    margin-top: 40px;
  }

  .order-aditional-info-wrapper h2 {
    color: #1F1F1F;
    font-size: 16px;
    margin-bottom: 6px;
  }

  .order-aditional-info-wrapper .retailer-block .retailer-avatar-block {
    padding-left: 12px;
    display: flex;
    align-items: center;
  }

  .order-aditional-info-wrapper .retailer-block .retailer-avatar-block .retailer-avatar-name {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 64px;
    color: #393939;
    margin-left: 8px;
  }

  .order-aditional-info-wrapper .order-aditional-info h2 {
    margin-bottom: 8px;
  }

  .order-aditional-info-row {
    display: flex;
    flex-wrap: wrap;
  }

  .order-aditional-info-row .order-aditional-info-block {
    flex: 0 0 318px;
    max-width: 318px;
    margin-bottom: 32px;
  }

  .order-aditional-info-row:last-child .order-aditional-info-block:last-child {
    margin-bottom: 0;
  }

  .order-aditional-info-block + .order-aditional-info-block {
    margin-left: 20px;
  }

  .order-aditional-info-block p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
    margin-bottom: 0;
    padding: 0 12px;
  }

  .account-page-container.cms-account-orders-view {
    padding-bottom: 100px;
  }

  .account-page-container.cms-account-orders-view .account-table .ant-table-tbody > tr > td {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  @media (min-width: 1200px) and (max-width: 1450px) {
    .content-container {
      padding: 0 30px;
    }

    .content-container.home-page-container {
      padding: 40px 30px 100px;
    }

    .category-page-container {
      padding-top: 32px;
      padding-bottom: 100px;
    }

    .account-page-container {
      padding-top: 32px;
      padding-bottom: 52px;
    }

    .product-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (max-width: 1280px) {
    .account-page-container.cms-account-orders-view .account-content {
      padding-left: 30px;
    }

    .account-page-container.cms-account-orders-view .account-title-container, .account-page-container.cms-account-orders-view .account-subtitle-container {
      max-width: unset;
      text-align: center;
      margin-left: 0;
    }
  }

  .ant-form-item-control-input{
    min-height: unset;
  }

  .menu-mobile-items {
    z-index: 9999;
    position: fixed;
    background: #ffffff;
    width: 100%;
    max-width: 330px;
    left: 0;
    height: 100%;
    top: 0;
    padding: 20px 25px 20px ;
    transition: all 0.5s;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-mobile-items.active{
    transform: translateX(0);
  }

  .menu-mobile-items > div {
    width: 100%;
    opacity: 1;
    max-width: 447px;
    background: #f4f4f4;
    z-index: 999;
    margin-top: 32px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  }



  .menu-mobile-items > div > span {
    pointer-events: all;
    opacity: 1;
    max-width: 447px;
    background: #f4f4f4;
    z-index: 999;
    width: 100%;
    height: 48px;
  }

  .menu-mobile-items > .ant-menu {
    display: block;
    margin-top: 28px;
    border: unset;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .menu-mobile-items > div > span.anticon {
    display: flex;
    max-width: unset;
    width: auto;
    align-items: center;
    justify-content: center;
    color: #6B6B6B;
  }

  .menu-mobile-items > div > span.anticon svg {
    font-size: 12px;
  }

  .menu-mobile-items > a.account-btn {
    display: block;
    margin-top: auto;
    border-top: 1px solid #E2E2E2;
    width: 100%;
    padding-top: 20px;
  }

  .menu-mobile-items > .account-btn.no-login {
    margin-top: auto;
    padding: 0;
    padding-top: 20px;
    border: unset;
    border-top: 1px solid #E2E2E2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    background: transparent;
    position: relative;
    margin-right: 0;
    height: auto;
    line-height: unset;
  }

  .menu-mobile-items > .account-btn.no-login a {
    line-height: 20px;
    position: static;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    height: 40px;
    padding: 6.4px 24px;
    font-size: 16px;
    border-radius: 0;
    color: #ffffff;
    background: #f16749;
    border-color: #f16749;
    width: 100%;
  }

  .menu-mobile-items > .account-btn.no-login a:hover {
    background: #ff9375;
    border-color: #ff9375;
  }

  .menu-mobile > .ant-btn {
    padding: 0;
    height: auto;
  }

  .menu-mobile-items > .ant-btn:first-child {
    padding: 0;
  }

  .cart .mini-cart{
    display: none
  }
//  ---- START formulário devolução ----

  .fd-top {
    padding-top: 50px;
  }

  .fd-top h1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #43043B;
    margin-bottom: 12px;
    text-align: center;
  }




  .char-count{
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(57, 57, 57, 0.4);
    text-align:right;
    margin: 0;
  }

  .link-center{
    text-align:center;
  }

  .table-center button{
    width:100%;
    border:0;
    height:40px;
    background:#F16749;
    margin-top:38px;
    color:white;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border: none;
    outline: none;
  }


  .table-inputs{
    display: flex;

    flex-direction: column;
    outline: none;
    align-items: flex-start;
    width:100%;
    height:48px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
  }

  .input-textarea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:12px;
    outline: none;
    align-items: flex-start;
    width:100%;
    height:78px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
  }


@media screen and (max-width:768px){

  .breadcrumb{
    display:none;
  }

  .fd-top{
    padding:0;
  }

  .fd-top h1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: #43043B;
    margin-bottom: 12px;
    text-align: center;
  }

}

//  ---- END formulário devolução ----

// ----- START help buying -----

  .hb-top {
    padding-top: 50px;
  }

  .hb-top h1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #43043B;
    margin-bottom: 16px;
    text-align: left;
  }

  .leftt {
    margin: 0;
    margin-top:16px;
    width: 566px;
    float:left;
    margin-bottom: 100px;
  }

  .leftt button{
    width:100%;
    border:0;
    height:40px;
    background:#F16749;
    margin-top:38px;
    color:white;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    border: none;
    outline: none;
  }

  .rightt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    margin-left:387px;
    margin-right:0;
    width:447px;
    height:374px;
    margin-top:0px;
    background: #FEF3F1;
    float:right;
  }

  .rightt h1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #393939;
  }

  .p-faqs1{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-left: 12px;
    line-height: 24px;
    margin-top:22px;
    margin-bottom:0;
    color: #6B6B6B;
  }
  .p-faqs{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-left: 12px;
    line-height: 24px;
    margin-top:16px;
    margin-bottom:0;
    color: #6B6B6B;
  }

  .button-faqs{
    margin-top:32px;
    width: 122px;

    padding: 10px 24px;
    background: #F16749;
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border:none;
  }

  .upload-text{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #F16749;
    padding-bottom:12px;
  }

  @media screen and (max-width:1460px){
    .rightt {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      margin-left:150px;
      margin-right:0;
      width:447px;
      height:374px;
      margin-top:0px;
      background: #FEF3F1;
      float:right;
    }
    .hb-top h1{
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #43043B;
      margin-bottom: 16px;
      text-align: left;
    }

  }
  @media screen and (max-width:1223px){

    .hb-top {
      padding-top: 0px;
    }

    .hb-top h1{
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      margin-left:16px;
      font-size: 30px;
      line-height: 38px;
      color: #43043B;
      margin-bottom: 16px;
      text-align: center;
    }
    .leftt {
      margin-top:10px;
      padding-left:24px;
      padding-right:24px;
      width: 100%;
      margin-bottom: 68px;
    }
      .rightt {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      width: 100%;
      margin-bottom:0;
      background: #FEF3F1;
    }
  }



// ---- END help buying ----

// ------START FAQS -----

  .faqs-top {
    padding-top: 44px;
    float:left;
    position: sticky;
    top:70px;
  }

  .faqs-top h1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-bottom:11px;
    line-height: 26px;
    color: #393939;
  }

  .faqs-top a{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin: 0px;
    padding: 0px;
    color: #6B6B6B;
  }

  .faqs-top a.active{
    color: #F16749
  }


  .faqs-center {
    margin: auto;
    width: 685px;
  }

  .principal-title{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #F16749;
    margin-top:48px;
  }

  .image-create-acc{
    margin-top:30px;
  }
  .image-create-acc-phone{
    margin-top:30px;
    margin-left:30px
  }

  @media (max-width: 933px){

    .image-create-acc{
      display:flex;
      justify-content: center;
      position:relative;

    }

    .image-create-acc-phone{
      display:flex;
      justify-content: center;
      margin-left:0;
      position:relative;
    }

  }

  .faqs-page .titles1{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #43043B;
    margin-top:32px;
    margin-bottom:12px;
  }

  .titles{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #43043B;
    margin-top:40px;
    margin-bottom:0;
  }

  .sub-title{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-top:12px;
    color: #43043B;
  }
  .body-text{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-top:12px;
    display: flex;
    margin-bottom:0px;
    align-items: center;
    color: #6B6B6B;
  }

  .timeline-mobile{
    display:none;
    padding:0;
  }

  @media screen and (max-width:1248px){
    .breadcrumb{
      margin-left:8px;
    }

    .faqs-top {
      margin-left:12px;
    }

    .faqs-center {
      margin-top:48px;
      width: 100%;
      padding-left:250px;
      padding-right:50px;
    }

    .principal-title{
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #F16749;
    }

    .titles1{
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #43043B;
    }

    .titles{
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #43043B;
    }

    .sub-title{
      font-family: Sora;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #43043B;

    }

    .body-text{
      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #6B6B6B;
    }

  }

  @media screen and (max-width:769px){

    .breadcrumb{
      display:none
    }

    .faqs-top {
      background: #fff;
      width:100%;
      padding-top:0;
      padding-left:24px;
      padding-right:24px;
      padding-bottom:0;
      margin-left:0;
      margin-right:0;
      text-align: center;
      margin-bottom:40px;
    }

    .faqs-top h1{

      font-family: Sora;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      margin-bottom:11px;
      padding-top:10px;
      line-height: 26px;
      color: #393939;
    }
    .faqs-center {
      width: 100%;
      padding-left:24px;
      padding-right:24px;

    }
    .timeline-desktop{
      display:none;
    }
    .timeline-mobile{
      padding:0;
      margin:0;
      display:block;
      width:100%;
      text-align: left;
    }
  }


// ----- END FAQS
  .cms-account-index .account-form-section:nth-child(2) .account-form-row:last-child .account-form-col {
    border-bottom: unset;
  }

  .account-nav-col.mobile {
    display: none;
  }


  @media (max-width: 1052px){
    .cms-account-index .content-row {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
    }

    .account-content {
      padding-left: 0;
      flex: 1;
      width: 100%;
    }

    .account-nav-col {
      flex: 1;
      max-width: unset;
      width: 100%;
      display:none;
    }

    .account-nav-col.mobile {
      display: flex;
      padding: 0 24px;
    }

    .account-form-section .account-form-row .account-form-col.right {
      flex: 0 auto;
      flex-direction: row;
    }

    .account-nav-col.mobile .ant-select {
      width: 100%;
    }

    .account-nav-col.mobile .ant-select a {
      color: #393939;
    }

    .account-page-container .ant-breadcrumb {
      display: none;
    }

    .account-title-container {
      /* margin: auto; */
      margin-top: 30px;
      margin-bottom: 24px;
    }
  }

  @media (min-width:992px) and (max-width:1199px){
    .account-page-container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media (max-width: 991px) {
    .cms-account-orders-index .account-content .ant-table-content table thead.ant-table-thead {
      display: none;
    }


    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell {
      display: flex;
      padding: 5px 0;
      border: unset;
    }

    .cms-account-orders-index .account-content .ant-table-content table tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
    }

    .cms-account-orders-index .account-content .ant-table-content table tbody {
      display: flex;
      flex-direction: column;
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:before {
      content: '# da encomenda';
      display: flex;
      flex: 0 50%;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #43043B;
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:nth-child(2):before {
      content: 'Data';
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:nth-child(3):before {
      content: 'Retalhista';
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:nth-child(4):before {
      content: 'Estado';
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:nth-child(5):before {
      content: 'Tipo';
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:nth-child(6):before {
      content: 'Total';
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:nth-child(7):before {
      display: none;
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:nth-child(7) {
      justify-content: center;
      padding-bottom: 0;
      padding-top: 31px;
      padding-bottom: 21px;
      border-bottom: 1px solid #E5E5E5;
    }

    .cms-account-orders-index .account-content .ant-table-content table td.ant-table-cell:nth-child(1) {
      padding-top: 0;
    }

    .cms-account-orders-index .account-content .ant-table-wrapper .ant-pagination.ant-table-pagination {
      display: none;
    }

    .cms-account-orders-index .account-content .ant-table-content table tr.ant-table-placeholder td.ant-table-cell:before {
      display: none;
    }

    .cms-account-orders-index .account-content .ant-table-content table tr.ant-table-placeholder td.ant-table-cell {
      justify-content: center;
    }
  }

  @media (max-width: 800px) {
    .cms-account-orders-view .account-content .ant-table-content table thead.ant-table-thead {
      display: none;
    }


    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell {
      display: flex;
      padding: 5px 0;
      border: unset;
    }

    .cms-account-orders-view .account-content .ant-table-content table tr {
      display: flex;
      flex-direction: column;
      padding-left: 20px
    }

    .cms-account-orders-view .account-content .ant-table-content table tr:nth-child(even) {
      background: #f4f4f4;
    }

    .cms-account-orders-view .account-content .ant-table-content table tbody {
      display: flex;
      flex-direction: column;
    }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:before {
      content: 'Produto';
      display: flex;
      flex: 0 50%;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #43043B;
    }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:nth-child(2):before {
      content: 'EAN';
    }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:nth-child(3):before {
      content: 'Preço/uni.';
    }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:nth-child(4):before {
      content: 'Tamanho do produto';
    }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:nth-child(5):before {
      content: 'Tipo do produto';
  }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:nth-child(6):before {
      content: 'Pacote do produto';
    }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:nth-child(7):before {
      content: 'Qtd';
    }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:nth-child(8):before {
      content: 'Subtotal';
    }

    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:nth-child(1) {
      padding-top: 0;
    }

    .cms-account-orders-view .account-content .ant-table-wrapper .ant-pagination.ant-table-pagination {
      display: none;
    }

    .cms-account-orders-view .account-content .ant-table-content table tr.ant-table-placeholder td.ant-table-cell:before {
      display: none;
    }

    .cms-account-orders-view .account-content .ant-table-content table tr.ant-table-placeholder td.ant-table-cell {
      justify-content: center;
    }

    .orderTotalInfoWrapper {
        justify-content: center;
        margin-right: 0;
    }

    .orderTotalInfoWrapper .orderTotalInfo {
        flex: 1;
        max-width: 100%;
        background: #F8F5F8;
        padding: 16px;
    }

    .cms-account-orders-view .content-row {
      padding: 0 24px;
    }

    .cms-account-orders-index .content-row {
      padding: 0 24px;
    }

    .cms-account-orders-view .account-title-container .ant-tag {
      display: block;
      width: fit-content;
      margin: 6px auto 8px;
    }

    .order-aditional-info-block + .order-aditional-info-block {
      margin-left: 0;
    }

    .account-page-container.cms-account-orders-view .account-content {
      padding-left: 0;
    }
  }

  @media (max-width:500px) {
    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell:before {
      flex: 0 60%
    }

  }

  @media (max-width: 767px) {
    .account-title-container {
      font-size: 24px;
      line-height: 32px;
    }
  }

  @media (min-width: 1350px){
    .account-page-container.cms-account-orders-view .account-content {
      padding-left: calc((100% - 1050px) - 208px);
      max-width: 1065px;
    }

    .account-page-container.cms-account-orders-view .account-title-container, .account-page-container.cms-account-orders-view .account-subtitle-container {
      margin-left: calc(100% - 1050px);
    }
  }

  .product-content{
    display: flex;
    flex-direction:row;
  }
  .product-ads-mobile{
    display:none;
  }
  @media (min-width: 1800px){
  .product-content .product-left-content{
    width:60%;
    margin-left:250px;
  }
  }
  @media (max-width: 1799px){
  .product-content .product-left-content{
    width: 80%;
    margin-left:25px;
  }
  }


  @media(max-width:1199px){
    .product-content .product-left-content{
      width:100%;
      margin: auto;
    }

    .product-content .product-left-content > div:nth-child(2){
      display:none;
    }
    .product-content{
      flex-direction:column;
    }
    .product-ads-mobile{
      display:flex;
      width:100%;
    }
  }

  @media (min-width: 1451px) and (max-width: 1700px){
    .product-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding-left: 30px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px){
    .product-grid {
      grid-template-columns: 1fr 1fr 1fr;
      padding-left: 60px;
    }

    .category-page-container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px){
    .product-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  @media (max-width: 767px){
    .product-grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: 1701px){
    .product-grid {
      padding-left: 30px;
    }
  }

  .top-filter .category-name .filter-btn {
    display: none;
  }

  .top-filter .category-name .filter-btn {
    display: none;
  }

  .layered-navigation .layered-navigation-title {
    display: none;
    justify-content: space-between;
  }

  @media (max-width:991px){
    .category-page-container {
      padding-left: 24px;
      padding-right: 24px;
    }

    .top-filter .category-name .filter-btn {
      display: none;
      padding: 0;
      font-size: 28px;
      color: #7B2E6F;
      height: auto;
    }
    .adjustBtn {
      margin-bottom: -162px;
    }

    .top-filter .category-name {
      justify-content: space-between;
      align-items: center;
    }

    .top-filter .category-name span.category-name {
      justify-content: flex-start;
    }

    .layered-navigation {
      position: fixed;
      right: 0;
      max-width: 100%;
      height: 100%;
      width: 100%;
      top: 0;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      z-index: -1;
    }

    .layered-navigation.active:before {
      content: '';
      background: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .layered-navigation .layerd-navigation-content {
      padding: 40px 24px;
      display: flex;
      flex-direction: column;
      max-width: 330px;
      height: 100%;
      overflow-y: scroll;
      margin-right: 0;
      margin-left: auto;
      background: #ffffff;
      z-index: 999;
      position: relative;
      transition: all 0.5s;
      transform: translateX(200%);
    }

    .layered-navigation.active .layerd-navigation-content {
      transform: translateX(0);
    }

    .layered-navigation.active {
      z-index: 999999;
    }

    .layered-navigation .layered-navigation-title {
      display: flex;
      margin-bottom: 40px;
    }

    .layered-navigation .layered-navigation-item {
      margin-right: 60px;
    }

    .layered-navigation .layered-navigation-title h4.ant-typography {
      color: #F16749;
      margin: 0;
      font-size: 24px;
      font-family: Sora;
      font-style: normal;
      font-weight: 600;
    }

    .layered-navigation .layered-navigation-title .close-btn {
      color: #393939;
      padding: 0;
      height: auto;
    }
  }

  @media (min-width:992px) {
    .top-filter .category-name h1 {
      display: flex;
    }

    .top-filter .category-name h1 span.category-name {
      padding-left: 12px;
      align-items: flex-end;
      height: 38px;
    }
    .ant-tooltip{
      max-width: unset
    }
  }

  .sub-category-container {
    padding-bottom: 40px;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 32px;
  }

  @media(max-width: 991px){
    .sub-category-container {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      border-bottom: 1px solid #E2E2E2;
    }
  }

  .sub-category-container .sub-categories-list {
    display: flex;
    width: calc(50% - 60px);
  }

  .sub-category-container .sub-categories-list .sub-categorie-item {
    flex: 0 auto;
    padding: 0 15px;
  }

  .sub-category-container .sub-categories-list .sub-categorie-item:first-child {
    padding-left: 0;
  }

  .sub-category-container .sub-categories-list .sub-categorie-item:last-child {
    padding-right: 0;
  }

  .sub-category-container .sub-categories-list .sub-categorie-item a {
    display: block;
  }

  .sub-category-container .sub-categories-list .sub-categorie-item a p {
    overflow: auto;
    margin: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #43043B;
    text-align: center;
  }

  .sub-category-container .search-box {
    display: flex;
    width: calc(50% - 60px);
    position: relative;
  }
  .sub-category-container .search-box.retailer-page {
    margin-right: 0;
    margin-left: auto;
  }
  @media (max-width: 991px) {
    .sub-category-container .search-box.retailer-page {
    margin-right: auto;
    margin-left: 0;
  }
  }
  

  .sub-category-container .search-box .ant-space {
    top: 50px;
  }

  .sub-category-container .search-box .ant-input-affix-wrapper {
    background-color: #F4F4F4;
    border: unset;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
  }

  .sub-category-container .search-box .ant-input-affix-wrapper input {
    background-color: #F4F4F4;
  }

  .sub-category-container .search-box .ant-input-search-button {
    border: unset;
    background-color: #F4F4F4;
  }

  @media (max-width: 991px){
    .sub-category-container .sub-categories {
      flex-direction: column-reverse;
    }

    .sub-category-container .sub-categories-list {
      width: 100%;
      margin-top: 24px;
      overflow-x: auto;
      display: flex;
    width: calc( 100vw - 48px);
    white-space: nowrap;
    padding-bottom: 10px;
    }

    .sub-category-container .sub-categories-list .sub-categorie-item {
      min-width: 100px;
      padding: 0 10px;
    }

    .sub-category-container .search-box {
      width: 90%;
    }

    .category-page-view > div > section.ant-layout.layout {
      width: 100%;
    }

    .category-page-view-has-sub-categories > div > section.ant-layout.layout {
      width: 100%;
    }

    .category-page-view-has-sub-categories .top-filter .category-name .filter-btn {
      position: relative;
      top: 85px;
    }

    .category-page-view-has-sub-categories .top-filter .category-name h1 {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-right: -28px;
    }

    .category-page-view-has-sub-categories .top-filter .category-name {
      justify-content: center;
    }
  }

  @media (max-width: 400px) {
    .sub-category-container .search-box {
      width: 87%;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px){
    .sub-category-container .sub-categories {
      justify-content: space-between;
    }

    .sub-category-container .sub-categories-list {
      width: calc(50% - 30px);
      overflow-x: scroll;
    }

    .sub-category-container .search-box {
      width: calc(50% - 30px);
    }
  }

  .category-page-view-has-sub-categories .top-filter .category-name span.category-name {
    display: none;
  }

  .sub-category-container .sub-categories {
    justify-content: space-between;
  }

  .top-filter .category-name.no-results h1 {
    font-size: 24px;
    line-height: 32px;
    color: #393939;
    margin: 0;
    margin-bottom: 12px;
  }

  .top-filter .category-name.no-results p{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
  }

  .search-page .top-filter .category-name.no-results{
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  .search-page .top-filter .category-suggestion h2{
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #F16749;
    width: 100%;
    text-align: center;
  }

  .search-page .top-filter .category-suggestion{
    margin-bottom: 32px;
  }

  @media (max-width: 991px){
    .search-page .top-filter .category-name.no-results{
      border-bottom: 1px solid rgba(57, 57, 57, 0.1);
    }
  }

  .search-page .top-filter .category-name.no-results {
    flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .search-page .top-filter .hp-top {
    width: 100%;
    padding: 0;
  }

  @media (max-width:991px){
    .hp-top .hp-cat-badge a {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .hp-top .hp-cat-badge a span span.icon {
    font-size: 14px;
    color: #F16749;
    padding-left: 12px;
  }

  .hp-top .hp-cat-badge a span {
    display: inline-flex;
    align-items: center;
  }

  .search-page .hp-top .hp-cat-badge a span {
    display: inline-flex;
    align-items: center;
  }

  .order-summary.active {
    transform: translateX(0);
  }

  .close-btn {
    padding: 0;
    margin-left: auto;
    height: auto;
    color: #393939;
  }

  .order-summary h5.ant-typography {
    display: flex;
    color: #7B2E6F!important;
  }

  .summary-btn {
    padding: 0;
    height: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  .summary-btn .icon {
    color: #43043B;
  }

  .summary-btn .total-price-value {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #43043B;
  }

  @media (min-width: 991px){
    .close-btn{
      display: none;
    }

    .summary-btn{
      display: none;
    }
  }

 //// sidebar admin page

  .search-sidebar{
  max-width:350px;
  width: 100%;
  display: none;
}

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: rgba(248, 245, 248, 1);
  }
    .sidebar-admin{
    width: 100%;
    max-width: 200px;
    transform: translateX(0);
    overflow: hidden;
    margin-top: 55px;
    top: 55px;
    height: calc(100vh - 58px);
    overflow-y: hidden;
    padding-top: 0;
    position: sticky;
    z-index: 1;

    }
    .sidebar-retailer{
    width: 100%;
    max-width: 200px;
    transform: translateX(0);
    overflow: hidden;
    margin-top: 55px;
    top: 55px;
    height: calc(100vh - 58px);
    overflow-y: hidden;
    padding-top: 0;
    position: sticky;
    z-index: 1;

    }
    .menu-sidebar{

      height: 100%;
     font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
      color: #6B6B6B;
      margin-top:20px;
      margin-bottom: auto;

    }
    .menu-sidebar-bottom{
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
      color: #6B6B6B;
      display: flex;
      margin-bottom: 25px;
    }


 @media (max-width: 800px){
  .sidebar-admin{
    width: 100% !important;
    max-width: 350px !important;
    flex: 0 0 350px !important;
    transition: all 0.5s;
    transform: translateX(-100%);
    height: 100%;
    margin-top: 0;
    padding-top: 0;
    top:0;
    position: absolute;
    z-index: 9;
    }
    .sidebar-retailer{
    width: 100% !important;
    max-width: 350px !important;
    flex: 0 0 350px !important;
    transition: all 0.5s;
    transform: translateX(-100%);
    height: 100%;
    margin-top: 0;
    padding-top: 0;
    top:0;
    position: absolute;
    z-index: 9;
    }
  }


  .sidebar-admin.active {
    transform: translateX(0);
  }
  .sidebar-retailer.active {
    transform: translateX(0);
  }



  .logo-admin-page{
    display:flex !important;
  }
  .logo-admin-page-sidebar{
    display: none !important;
  }
  .open-button-admin{
   font-size: 20px !important;
   margin:0;
   padding:0;
   margin-left: 0 !important;
   display: none;
  }
  @media (max-width: 800px) {
    .logo-admin-page{
      display: none !important;
    }
    .menu-sidebar{
      margin-top:44px;
      margin-bottom: auto;
    }
    .logo-admin-page-sidebar{
      display: inherit !important;
      margin-left: 24px !important;
      margin-top: 16px !important;
      width:94px !important;
      height: 32px;
    }

    .open-button-admin{
      display: inherit;
      font-size: 24px !important;
      color: black;
      margin:0;
      padding:0;
    }
  }

  .ant-carousel  .homepage-carousel-category-products .slick-track{
    margin: auto;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: #7B2E6F;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-input{
    border-radius: 0
  }

  .ant-modal-header {
    border: unset;
    padding: 24px;
  }

  .ant-modal-close-x {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .location-modal .ant-input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .location-modal .autocomplete-dropdown-container .suggestion-item, .location-modal .autocomplete-dropdown-container .suggestion-item--active {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-dropdown-menu-item {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .cms-account-index .account-content .ant-input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .cms-account-index .account-content .autocomplete-dropdown-container .suggestion-item, .cms-account-index .account-content .autocomplete-dropdown-container .suggestion-item--active {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .cms-account-orders-index .ant-table-tbody .ant-table-row:hover {
    cursor: pointer;
  }

  .sub-category-container .search-box .ant-input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    color: #6B6B6B;
  }

  .sub-category-container .search-box .ant-btn .anticon {
    color:  #6B6B6B;
  }

  .sub-category-container .search-box .ant-input::placeholder {
    color: #9E9E9E!important;
  }

  .ant-select-item-option-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    color: #6B6B6B;
  }

  .ant-modal-footer {
    border: unset;
    padding: 16px 24px 24px;
  }

  .ant-modal-footer .ant-btn {
    height: 36px;
    font-size: 14px;
  }

  .cart .ant-form-vertical .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .cart .ant-form-vertical .ant-form-item .ant-form-item-label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  .cart .ant-modal-content .ant-form-vertical .ant-form-item input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  .checkout-index-index footer > .ant-space > .ant-space-item:first-child {
    display: none;
  }

  .checkout-index-index footer > .ant-space > .ant-space-item:nth-child(2) {
    display: none;
  }

  .checkout-index-index footer > .ant-space > .ant-space-item:nth-child(3) .ant-space {
    margin-top: 0;
  }

  .checkout-index-index footer {
    padding: 28px 0;
  }

  .cms-account-index .account-content .ant-picker-large .ant-picker-input > input {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .cms-account-index .account-content .ant-form-item-label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  html.faqs-page {
    scroll-behavior: smooth;
  }

  .sub-category-container .search-box .ant-input-affix-wrapper input:focus {
    background: #ffffff;
  }

  .sub-category-container .search-box .ant-input-affix-wrapper-focused {
    background: #ffffff;
    border: 1px solid #D9D9D9;
    box-shadow: unset;
    border-right: unset;
  }

  .sub-category-container .search-box .ant-input-affix-wrapper-focused + .ant-input-group-addon {
    border: 1px solid #D9D9D9;
    border-left: unset;
  }

  .sub-category-container .search-box .ant-input-affix-wrapper-focused + .ant-input-group-addon .ant-input-search-button {
    background: #ffffff;
  }

  .sub-category-container .search-box .ant-input-search .ant-input-group {
    background: #F4F4F4;
    box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 12%);
  }

  @media (max-width: 800px) {
    .sub-category-container .search-box .ant-input-search .ant-input-group {
    background: #F4F4F4;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
  }
  }

  .sub-category-container .search-box .ant-input-affix-wrapper:focus {
    box-shadow: unset;
  }

  .sub-category-container .search-box .ant-input-affix-wrapper, .sub-category-container .search-box .ant-input-affix-wrapper input {
    transition: all 0.4s;
  }

  .home-page .ant-carousel .slick-list .slick-slide {
    padding: 0 15px;
  }

  .account-nav-col.mobile .ant-select .ant-select-selection-item {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    color: #393939;
  }

  .ant-select-item-option-content a {
    color: #6B6B6B;
    width: 100%;
    display: block;
  }

  .ant-select-item-option-content a.active {
    color: #f16749;
  }

  @media (max-width:767px) {
    .cms-account-index .account-form-section .account-form-row .account-form-col .account-form-value {
      justify-content: flex-start;
    }
  }

  @media (max-width: 991px) {
    .cms-account-orders-view .account-content .ant-table-content table td.ant-table-cell span {
      display: flex;
      flex: 1;
    }
  }

  .account-nav-col.mobile .ant-select .anticon {
    color: #393939;
  }

  .ant-checkbox + span {
    font-family: Lato;
    font-weight: normal;
    font-style: normal;
  }

  .change-address-modal .ant-modal-confirm-btns .ant-btn {
    height: 36px;
    font-size: 14px;
  }

  .change-address-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
    background: #7B2E6F;
    border-color: #7B2E6F;
  }

  .change-address-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover {
    background: #87487c;
    border-color: #87487c;
  }

  .timeline-desktop .ant-timeline-item {
    padding-bottom: 10px;
  }

  .timeline-desktop .ant-timeline-item-content {
    min-height: unset;
  }

  html.become-retailer-page {
    scroll-behavior: smooth;
  }

  html.brands-page {
    scroll-behavior: smooth;
  }

  .text-content{
    font-family: Lato;
  }

  .text-content ol, .text-content ul{
    padding-left: 40px;
  }

  .text-content h1,
  .text-content h2,
  .text-content h3,
  .text-content h4,
  .text-content h5,
  .text-content h6 {
    color: #43043b !important;
    font-family: Sora;
    font-weight: bold;
    line-height: 1.35;
    margin-bottom: 12px;
    margin-top: 40px;
  }

  .text-content h1 {
    font-size: 20px;
  }
  .text-content h2 {
    font-size: 20px;
  }
  .text-content h3 {
    font-size: 20px;
  }
  .text-content h4 {
    font-size: 20px;
  }
  .text-content h5 {
    font-size: 20px;
  }
  .text-content h6 {
    font-size: 18px;
  }

  .text-content strong {
    display: block;
    color: #43043B;
  }

  .text-content .ql-indent-1 {
    padding-left: 20px;
  }

  .text-content .ql-indent-2 {
    padding-left: 30px;
  }

  .text-content .ql-indent-3 {
    padding-left: 40px;
  }

  .faqs-page .timeline-desktop .ant-timeline-item:last-child .ant-timeline-item-tail {
    display: none;
  }

  .text-content p {
    margin: 0;
  }

  ol {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol > li {
    list-style-type: none;
  }


  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

  ol li:before {
    content: counter(list-0, decimal) '. ';
    color: #43043B;
  }

  ol li.ql-indent-1 {
    counter-increment: list-1;
  }

  ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

  ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-2 {
    counter-increment: list-2;
  }

  ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

  ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-3 {
    counter-increment: list-3;
  }

  ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

  ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-4 {
    counter-increment: list-4;
  }

  ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

  ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-5 {
    counter-increment: list-5;
  }

  ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

  ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-6 {
    counter-increment: list-6;
  }

  ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

  ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

  ol li.ql-indent-7 {
    counter-increment: list-7;
  }

  ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

  ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

  ol li.ql-indent-8 {
    counter-increment: list-8;
  }

  ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

  ol li.ql-indent-8 {
    counter-reset: list-9;
  }

  ol li.ql-indent-9 {
    counter-increment: list-9;
  }

  ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

  .ant-menu-horizontal .submenu-dropdown .submenu-image {
    position: relative;
  }

  .ant-menu-horizontal .submenu-dropdown .submenu-image > a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0;
  }

  .sub-category-container .sub-categories-list .sub-categorie-item a .ant-image {
    background: #F8F5F8;
  }

  .sub-category-container .sub-categories-list .sub-categorie-item a .ant-image .ant-image-img {
    padding:  15px;
  }

  @media (max-width: 767px) {

    .content-container.home-page-container {
      padding: 32px 24px 100px;
    }

    .hp-top h1 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 800px) {
    .hp-section {
      display: flex;
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 40px;
    }

    .hp-section .hp-section-link {
      order: 1;
      margin: 0;
      text-decoration: unset;
      padding: 10px 0;
      border: 1px solid #7B2E6F;
      margin-top: 32px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    .hp-top .hp-cat-badge a {
      background-size: cover;
      font-size: 18px;
      line-height: 24px;
      padding: 13px 0;
      text-align: center;
      margin-top: 16px;
    }

    .hp-top .hp-cat-badges {
      justify-content: flex-start;
    }

    .content-container.home-page-container {
      padding: 32px 24px 100px;
    }

    .hp-section .ant-carousel {
      max-width: 100%;
    }

    .hp-section + .hp-section {
      padding-top: 40px;
    }

    .hp-section .ant-carousel .slick-dots {
      position: static;
      margin-top: 30px;
      margin-bottom: 0;
    }

    .hp-section .ant-carousel .slick-dots .slick-active {
      width: 6px;
      height: 6px;
      background: #43043B;
      opacity: 1;
    }

    .hp-section .ant-carousel .slick-dots li {
      width: 6px;
      height: 6px;
      background: #43043B;
      opacity: 0.2;
      border-radius: 50%;
    }

    .hp-section .ant-carousel .slick-dots li button {
      background: transparent!important;
    }
  }

  .menu-mobile.active:before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99;
    left: 0;
    top: 0;
  }

  .menu-mobile-items .ant-menu-submenu-title {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #393939;
    padding: 0!important;
  }

  .menu-mobile-items .ant-menu-sub.ant-menu-inline {
    background: transparent;
  }

  .menu-mobile-items .ant-menu-sub.ant-menu-inline .ant-menu-item {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #393939;
    height: auto;
    padding-left: 40px!important;
    margin: 12px 0;
  }

  .menu-mobile-items .ant-menu-sub.ant-menu-inline .ant-menu-item a {
    color: #393939;
  }

  .menu-mobile-items > .ant-menu >  .ant-menu-item-only-child {
    padding: 0!important;

  }

  .menu-mobile-items > .ant-menu > .ant-menu-item-only-child a {
    color: #393939;
    display: block;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  .menu-mobile-items .ant-menu-submenu-title .ant-menu-submenu-arrow {
    color: #393939;
  }

  @media (min-width: 992px) and (max-width: 1400px) {
    .hp-top .hp-cat-badge a {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .menu-mobile > .ant-btn .anticon {
    line-height: unset;
  }

  @media (max-width: 350px) {
    .hp-top .hp-cat-badge a {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .ant-breadcrumb > span:not(:last-child) .ant-breadcrumb-link {
    cursor: pointer;
  }

  .timeline-mobile .ant-select-selection-item {
    font-family: Lato;
  }

  .timeline-mobile .ant-select-arrow {
    color: #393939;
  }

  .timeline-mobile .ant-select-selection-item a {
    color: #393939;
  }

  .timeline-mobile .ant-select-selection-placeholder {
    font-family: Lato;
  }

  @media (max-width: 991px) {
    .faqs-top {
      padding: 0;
      background: transparent;
      z-index: 1;
      top: 48px;
    }

    .faqs-center {
      padding: 0;
    }

    .faqs-page .principal-title {
      text-align: left;
      padding: 0;
      font-size: 24px!important;
      line-height: 32px!important;
    }

    .faqs-top h1 {
      padding-top: 8px;
    }

    .leftt {
      padding: 0;
      float: none;
    }
  }

  .help-buying .ant-breadcrumb {
    padding-top: 32px;
  }

  .help-buying .hb-top {
    padding-top: 40px;
  }

  .help-buying .leftt button {
    margin-top: 4px;
  }

  .help-buying .rightt {
    height: auto;
    margin-left: auto;
  }

  @media (max-width: 991px) {
    .help-buying  .leftt {
      padding: 0 24px;
      margin-top: 32px;
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .help-buying .leftt {
      max-width: 500px;
      margin-top: 0;
    }

    .help-buying  .rightt {
      max-width: 447px;
      margin-right: 24px;
    }

    .help-buying .hb-top {
      margin-bottom: 20px;
    }

    .help-buying .hb-top h1{
      text-align: left;
      margin-left: 24px;
    }
    .help-buying .hb-top p {
      text-align: left;
      margin-left: 24px;
    }
  }

  @media (min-width: 1201px) and (max-width: 1300px) {
    .help-buying .leftt {
      max-width: 566px;
      margin-top: 0;
      padding: 0;
    }

    .help-buying  .rightt {
      max-width: 447px;
      margin-right: 24px;
    }

    .help-buying .hb-top {
      margin-bottom: 20px;
    }
  }

  .help-buying .breadcrumb {
    margin-left: 0;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .help-buying .ant-breadcrumb {
      padding: 32px 24px 0 24px;
    }
  }

  @media (min-width: 1200px)  {
    .help-buying .hb-top h1 {
      text-align: left;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    .principal-title {
      font-size: 32px !important;
      line-height: 40px !important;
      text-align: center;
      margin: 0!important;
      padding: 0 24px;
    }
  }

  .cms-account-orders-view .account-content .ant-table-content table tr td {
    background: transparent;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .hp-top .hp-cat-badge a {
      padding: 20px 0;
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .hp-top .hp-cat-badges {
      margin: 0!important;
    }

    .hp-section .ant-carousel .slick-slider .slick-prev {
      left: 0;
    }

    .hp-section .ant-carousel .slick-slider .slick-next {
      right: 0;
    }
  }

  @media (max-width: 350px) {
    .menu-mobile-items {
      height: 100%;
    }

    .principal-title {
      font-size: 24px !important;
      line-height: 32px !important;
      text-align: center;
      margin: 0!important;
      padding: 0 24px;
    }

    .sub-category-container .search-box {
      width: 85%;
    }

    .order-aditional-info-row .order-aditional-info-block {
      flex: auto;
      max-width: 318px;
    }
  }

  @media (max-width: 767px) {
    .faqs-top {
      padding: 0;
      background: transparent;
      z-index: 1;
      top: 70px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .hp-top .hp-cat-badge a {
      font-size: 20px;
      line-height: 32px;
    }
  }
  .page-title{
    display: flex;
    margin-left: 238px;
  }
  @media (max-width: 1052px) {
    .page-title{
    margin-left: 0;
  }
  }
  .table-title1{
    margin:0;
    margin-right: 10px;
    font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
color: #393939;
  }
  .table-title2{
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */


color: #393939;
  }
  .table-title3{
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
margin-bottom: 10px;
/* identical to box height, or 171% */


color: #6B6B6B;
  }
  @media (max-width:991px) {
    .page-title{
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  }
  .tableTitlesWrapper{
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    margin-bottom: 5px,
  }
  @media(max-width: 800px){
    .tableTitlesWrapper{
      padding: 10px;
      padding-bottom: 2px;
      background-color: #F8F5F8;
      margin-bottom: 10px;
    }
  }
   @media(max-width: 640px){
    .tableTitlesWrapper{
      grid-template-columns: 1fr 1fr;

    }
  }
  @media(max-width: 520px){
    .tableTitlesWrapper{
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr ;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
    margin-top: 5px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
}
.new-address {
display: flex;
flex-direction: column;
width: 100%;
margin-top: 20px;
font-family: 'Lato';
font-style: 'normal';
font-weight: 'normal';
font-size: 15px;
color: #43043b;
}
.button-new-address {
  display: flex;
  flex-direction: column;
  width: 90%;
    padding: 10px;
    border: 0;
    background-color: transparent;
}
& .button-new-address:hover {
  background-color: rgb(239, 239, 239);
}
.edit-icon {
font-size: 20px;
color: #6B6B6B;
}
span.anticon.anticon-edit.edit-icon:hover {
    color: #f16749;
}

.addressInfo {
  color: #6B6B6B;
  margin: 0;
  padding: 0;
  @media (max-width: 768px) {
    text-align: start;
  }
}
.promotion-category {
  display: flex;
  justify-content: right;
  margin-top: -80px;
}
.promotion-category-name {
  margin-bottom: -90px !important;
}
.promotion-category-title {
  color: rgb(241, 103, 73) !important;
}
.promotion-name {
  font-size: 36px !important;
}
.promotion-image {
  position: absolute;
  max-width: 72px;
  top: -10px;
  margin-left: -110px;
}
.promotion-image-home {
  position: absolute;
    top: 0;
    left: -10px;
    max-width: 72px;
}
@media (max-width: 900px) {
  .promotion-image {
    max-width: 62px;
    top:0;
    margin-left:-100px;
}
.promotion-image-home {
  max-width: 62px;
}
}
@media (max-width: 850px) {
  .promotion-image {
    max-width: 62px;
    top:0;
    margin-left:-85px;
}
}
@media (max-width: 767px) {
  .promotion-image {
    max-width: 62px;
    top:0;
    margin-left:-140px;
}
}

@media (max-width: 700px) {
  .promotion-image {
    max-width: 62px;
    top:0;
    margin-left:-100px;
}
}
@media (max-width: 580px) {
  .promotion-image {
    max-width: 62px;
    top:0;
    margin-left:-120px;
}
}
@media (max-width: 991px) {
  .promotion-category {
  display: flex;
  justify-content: left;
  margin-top: 0px;

}
.promotion-category-name {
  margin-bottom: 0 !important;
}
}
@media (max-width: 520px) {
  .promotion-image {
    margin-left:-100px;
    max-width: 52px;
}
.promotion-image-home {
  max-width: 52px;
}
}
@media (max-width: 450px) {
  .promotion-image-home {
  top: 20px;
}
}
@media (max-width: 430px) {
.promotion-name {
  font-size: 30px !important;
}
}
@media (max-width: 400px) {
  .promotion-image-home {
  max-width: 45px;
  top: 30px;
}
}
@media (max-width: 450px) {
.promotion-image {
  top: 10px;
  margin-left: -80px;
}
}
 @media (max-width: 370px) {
.promotion-image {
  margin-left: -70px; 
}
}
@media (max-width: 300px) {
.promotion-image {
  max-width: 45px;
}
.promotion-image-home {
  max-width: 35px;
  top: 50px;
}
} 

.black-wednesday-label {
  margin-left: 10px; 
  display: block;
}

/* @media (max-width: 960px) {
  .promotion-image-home {
  right: 5px;
  width: 62px;
}
}
@media (max-width: 900px) {
  .promotion-image-home {
  right: 30px;
}
}
@media (max-width: 900px) {
  .promotion-image-home {
  right: 20px;
}
}
@media (max-width: 850px) {
  .promotion-image-home {
  right: 15px;
}
}
@media (max-width: 750px) {
  .promotion-image-home {
  right: 10px;
}
}
@media (max-width: 700px) {
  .promotion-image-home {
    width: 52px;
    top: 10px;
}
}
@media (max-width: 630px) {
  .promotion-image-home {
    top: 20px;
    right: 0px;
}
}
@media (max-width: 600px) {
  .promotion-image-home {
    top: 0px;
    right: 20px;
}
}
@media (max-width: 500px) {
  .promotion-image-home {
    right: 10px;
    top: 0px;
}
}
@media (max-width: 450px) {
  .promotion-image-home {
    right: 0px;
    top: 20px;

}
}
@media (max-width: 400px) {
  .promotion-image-home {
    width: 46px;
    top: 30px;
    right: -5px;

}
}
@media (max-width: 350px) {
  .promotion-image-home {
    top: 50px;
    right: -10px;

}
}
@media (max-width: 300px) {
  .promotion-image-home {
    width: 40px;
    top: 50px;
    right: -10px;

}
} */
.select-sort {
    max-width: 270px;
    font-family: Lato;
    font-size: 14px;
    color: rgb(158, 158, 158);
    width: 100%;
    float: right;
    margin-bottom: 30px;
}

.wrapper-filters {
  display: none;
  width: 100%;
    flex-direction: row;
    margin-top: 20px;
}
.filter-by {
  background-color: transparent;
  max-width: 200px;
  width: 100%;
  border: 1px solid rgba(123, 46, 111, 0.2);
  color: #7B2E6F;
  line-height: 24px;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  margin-right: 20px;
  font-size: 14px;
  font-family: Lato;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.loading-filter-by {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}
.filter-icons {
  font-size: 20px;
  margin-right: 12px;
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
    -moz-transform: rotate(90deg);   /* Firefox */
    -ms-transform: rotate(90deg);   /* IE 9 */
    -o-transform: rotate(90deg);   /* Opera */
    transform: rotate(90deg);
}
.filter-select {
  position: absolute;
    z-index: 9;
    margin-top: 53px;
    /* right: 0; */
    margin-left: 220px;
    max-width: 192px;
    width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
/* drop-shadow/0.12+0.8+0.5 */

box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */

display: flex;

/* Neutral / 60 - P */

color: #6B6B6B;

}
.each-filter {
  padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 991px) {
  .wrapper-filters {
    display: flex;
  }
  .select-sort {
    display: none;
  }
}
@media (max-width: 465px) {
  .filter-select {
    max-width: 100%;
    width: 40%;
    margin-left: 0;
    right: 35px;
    min-width: 130px;
  }
}
@media (max-width: 450px) {
  .sub-category-container {
    margin-bottom: 0;
  }
  .filter-by {
    margin-right: 10px;
  }
}
@media (max-width: 400px) {
  .filter-select {
    right: 30px;
  }
}
@media (max-width: 370px) {
  .filter-select {
    font-size: 12px;

  }
}
@media (max-width: 350px) {
  .filter-by {
    font-size: 12px;
    padding: 8px;
  }
  .filter-icons {
    font-size: 16px;
    margin-right: 5px;
  }
  .filter-select {
    margin-top: 48px;
  }
}
@media (max-width: 320px) {
  .filter-select {
    right: 20px;
  }
}
@media (max-width: 300px) {
  .wrapper-filters {
    flex-direction: column;
    margin-top: 0;
  }
  .filter-by {
    max-width: 100%;
    margin-top: 20px;
    font-size: 14px;
  }
  .filter-icons {
    font-size: 18px;
    margin-right: 10px;
  }
  .filter-select {
    margin-top: 130px;
    width: 80%;
    right: 35px;
  }
}

.banner-carousel {
  height: 175px;
  width: 100%;
  margin-top: 57px;
  position: relative;
}
@media (min-width: 801px) and (max-width: 1200px) {
  .banner-carousel {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 800px) {
  .banner-carousel {
    margin-top: 20px;
    order: 2;
  }
}
@media (max-width: 600px) {
  .banner-carousel {
    height: 145px;
  }
}

.notification-wishlist {
  padding: 10px;
  padding-left: 17px;
  padding-right: 17px;
  width: 100%;
  .ant-notification-notice-message {
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */


/* Neutral / 60 - P */

color: #6B6B6B;
    margin-bottom: 0;
  }
  .ant-notification-notice-close {
    display: none ;
  }
}
.wishlist-wrapper {
    display: grid;
    grid-template-columns: 208px 208px 208px;
    padding-left: 25px;
    padding-right: 25px;
    grid-gap: 50px;
    margin-top: 20px;
}
.image-wishlist {
  height: 200px;
}
.button-wishlist {
  font-size: 14px;
}
@media (max-width: 1070px) {
  .wishlist-wrapper {
    grid-template-columns: 158px 158px 158px;
  }
}
@media (max-width: 1052px) {
  .wishlist-wrapper {
    justify-content: center;
    grid-template-columns: 208px 208px 208px;
  }
}
@media (max-width: 830px) {
  .wishlist-wrapper {
    grid-template-columns: 158px 158px 158px;
  }
}
@media (max-width: 767px) {
  .image-wishlist {
    height: 150px;
  }
}
@media (max-width: 700px) {
  .wishlist-wrapper {
    //grid-gap: 25px;
  }
}
@media (max-width: 650px) {
  .wishlist-wrapper {
    grid-template-columns: 208px 208px;
  }
}
@media (max-width: 550px) {
  .wishlist-wrapper {
    grid-template-columns: 158px 158px;
  }
}
@media (max-width: 420px) {
  .wishlist-wrapper {
    grid-template-columns: 130px 130px;
  }
}
@media (max-width: 380px) {
  .wishlist-wrapper {
    grid-gap: 50px;
    grid-template-columns: 120px 120px;
  }
}
@media (max-width: 350px) {
  .wishlist-wrapper {
    grid-gap: 25px;
    grid-template-columns: 120px 120px;
  }
}
@media (max-width: 320px) {
  .wishlist-wrapper {
    grid-gap: 25px;
    grid-template-columns: 110px 110px;
  }
}
@media (max-width: 300px) {
  .wishlist-wrapper {
    grid-gap: 15px;
    grid-template-columns: 105px 105px;
  }
}
.link-retailer {
    display: flex;
    flex-direction: row;
  }
  .link-retailer-after-hour {
      display: flex;
      flex-direction: row;
  }
  @media (max-width: 800px) {
    .link-retailer-after-hour {
      display: flex;
      flex-direction: column;
    }
  }
  .link-retailer:hover {
    text-decoration: underline;
    text-decoration-color: #43043b;
  }
  .link-retailer-after-hour:hover {
    text-decoration: underline;
    text-decoration-color: #fff;
  }
  .after-hours-without-products {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #43043B;
  }
  .after-hours-without-products-subtitle {
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #393939;

  }
`
